// assets
import { ApartmentOutlined, HomeFilled, HomeOutlined } from '@ant-design/icons';
import { Card, Divider, Grid, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

import AccessibleTabs2 from 'pages/AccessibleTabs2';
import { GlobalStoreContext } from 'GlobalStoreProvider';
import JWTContext from 'contexts/JWTContext';
// project import
import MainCard from 'components/MainCard';
// material-ui
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import PropTypes from 'prop-types';
import { getUserSectionData } from '../../_api/rsuAPI';
import { useTheme } from '@mui/material/styles';

// ==============================|| BREADCRUMBS ||============================== //

const Breadcrumbs = ({
  card,
  divider = true,
  icon,
  icons,
  maxItems,
  navigation,
  rightAlign,
  separator,
  title,
  titleBottom,
  sx,
  ...others
}) => {
  const theme = useTheme();
  const location = useLocation();
  const [main, setMain] = useState();
  const [item, setItem] = useState();
  const { section } = useContext(JWTContext);  // Get section data and defaultTab
  const [sections, setSections] = useState([]);
  const{getValue,setKeyValue}=useContext(GlobalStoreContext);
  let currentPath = location.pathname;

  // only used for component demo breadcrumbs
  if (currentPath.includes('/components-overview/breadcrumbs')) {
    currentPath = '/apps/kanban/board';
  }

  if (currentPath.includes('/apps/kanban/backlogs')) {
    currentPath = '/apps/kanban/board';
  }

  useEffect(() => {
    if (currentPath.includes('/apps/profiles/user/payment')) {
      setItem(undefined);
    }
  }, [item, currentPath]);


  useEffect(() => {
    // Set section data after fetching from context
    if (section) {
      setSections(section);
    }
  }, [section]);
  const iconSX = {
    marginRight: theme.spacing(0.75),
    marginTop: `-${theme.spacing(0.25)}`,
    width: '1rem',
    height: '1rem',
    color: theme.palette.secondary.main
  };

  const fetchData = async () => {
    try {
     

        const response =  await  getUserSectionData();
  
        setSections((response.section));
        setKeyValue("sectionList",response.section);

       
    } catch (error) {
        console.error('Error:', error.message);
    }
}


  // set active item state
  const getCollapse = (menu) => {
    if (menu.children) {
      menu.children.filter((collapse) => {
        if (collapse.type && collapse.type === 'collapse') {
          getCollapse(collapse);
          if (collapse.url === currentPath) {
            setMain(collapse);
            setItem(collapse);
          }
        } else if (collapse.type && collapse.type === 'item') {
          if (currentPath.includes(collapse.url)) {
            setMain(menu);
            setItem(collapse);
          }
        }
        return false;
      });
    }
  };

  useEffect(() => {
    navigation?.items?.map((menu) => {
      if (menu.type && menu.type === 'group') {
        getCollapse(menu);
      }
      return false;
    });
  });


  useEffect(() => {
    // Function to fetch both maps and data concurrently
    const fetchDataAsync = async () => {
      try {
        // Fetch both functions in parallel
        await Promise.all([fetchData()]);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      }
    };
      // Initial fetch
  fetchDataAsync();

 
  },[]);

  // item separator
  const SeparatorIcon = separator;
  const separatorIcon = separator ? <SeparatorIcon style={{ fontSize: '0.75rem', marginTop: 2 }} /> : '/';

  let mainContent;
  let itemContent;
  let breadcrumbContent = <Typography />;
  let itemTitle = '';
  let CollapseIcon;
  let ItemIcon;

  // collapse item
  if (main && main.type === 'collapse' && main.breadcrumbs === true) {
    CollapseIcon = main.icon ? main.icon : ApartmentOutlined;
    mainContent = (
      <Typography component={Link} to={document.location.pathname} variant="h6" sx={{ textDecoration: 'none' }} color="textSecondary">
        {icons && <CollapseIcon style={iconSX} />}
        {main.title}
      </Typography>
    );
    breadcrumbContent = (
      <MainCard
        border={card}
        sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
        {...others}
        content={card}
        shadow="none"
      >
        <Grid
          container
          direction={rightAlign ? 'row' : 'column'}
          justifyContent={rightAlign ? 'space-between' : 'flex-start'}
          alignItems={rightAlign ? 'center' : 'flex-start'}
          spacing={1}
        >
          <Grid item>
            <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
              <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                {icons && <HomeOutlined style={iconSX} />}
                {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
                {(!icon || icons) && 'Home'}
              </Typography>
              {mainContent}
            </MuiBreadcrumbs>
          
          </Grid>
          {title && titleBottom && (
            <Grid item sx={{ mt: card === false ? 0.25 : 1 }}>
              <Typography variant="h2">{main.title}</Typography>
            </Grid>
          )}
          
        </Grid>
        {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
      </MainCard>
    );
  }

  // items
  if (item && item.type === 'item') {
    itemTitle = item.title;

    ItemIcon = item.icon ? item.icon : ApartmentOutlined;
    itemContent = (
      <Typography variant="subtitle1" color="textPrimary">
        {icons && <ItemIcon style={iconSX} />}
        {itemTitle}
      </Typography>
    );

    // main
    if (item.breadcrumbs !== false) {
      breadcrumbContent = (
        <MainCard
          border={card}
          sx={card === false ? { mb: 3, bgcolor: 'transparent', ...sx } : { mb: 3, ...sx }}
          {...others}
          content={card}
          shadow="none"
        >
          <Grid
            container
            direction={rightAlign ? 'row' : 'column'}
            justifyContent={rightAlign ? 'space-between' : 'flex-start'}
            alignItems={rightAlign ? 'center' : 'flex-start'}
            spacing={1}
          >
            
            <Grid item>
              <MuiBreadcrumbs aria-label="breadcrumb" maxItems={maxItems || 8} separator={separatorIcon}>
                <Typography component={Link} to="/" color="textSecondary" variant="h6" sx={{ textDecoration: 'none' }}>
                  {icons && <HomeOutlined style={iconSX} />}
                  {icon && !icons && <HomeFilled style={{ ...iconSX, marginRight: 0 }} />}
                  {(!icon || icons) && 'Home'}
                </Typography>
              
                {mainContent}
                {itemContent}
              </MuiBreadcrumbs>
            </Grid>
            
          
        
          </Grid>
          <>
          {Array.isArray(sections) && sections.length > 1 && (
          <Grid item lg={12} md={12} sm={12} xs={12}>
      <Card
        sx={{
          px: 1,
          py: 1,
          mb: 1,
          width: '100%',
          height: '100%',
          marginTop: '10px',
          border: '1px solid gray',
          borderRadius: '8px',
        }}
      >
       
       <AccessibleTabs2 section={sections} defaultTab={0} />
          
      
      </Card>
    </Grid>
          )}
          </>
          {card === false && divider !== false && <Divider sx={{ mt: 2 }} />}
        </MainCard>
      );
    }
  }

  return breadcrumbContent;
};

Breadcrumbs.propTypes = {
  card: PropTypes.bool,
  divider: PropTypes.bool,
  icon: PropTypes.bool,
  icons: PropTypes.bool,
  maxItems: PropTypes.number,
  navigation: PropTypes.object,
  rightAlign: PropTypes.bool,
  separator: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  title: PropTypes.bool,
  titleBottom: PropTypes.bool,
  sx: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Breadcrumbs;
