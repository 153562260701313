import { Box, Tab, Tabs } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

import { GlobalStoreContext } from 'GlobalStoreProvider';
import JWTContext from 'contexts/JWTContext'; // Import context

export default function AccessibleTabs2({ section }) {
  const { store,setKeyValue,getValue } = useContext(GlobalStoreContext); // Get selected tab and setSelectedTab from context
  const [value, setValue] = useState(0);

  // Synchronize local state with context when selectedTab changes
  useEffect(() => {
    if(getValue("selectedSection")===null||getValue("selectedSection")===undefined){
      setKeyValue("selectedSection",0);
    }else{
      setValue(getValue("selectedSection"))
    }
      
  }, [getValue("selectedSection")]);

  // Handle tab change
  const handleChange = (event, newValue) => {
 //   console.log(event,newValue)
    setKeyValue("selectedSection",newValue);
    setValue(newValue)
    window.location.reload();

  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        
        {section.map((item, index) => (
          <Tab key={index} label={item} />
        ))}
      </Tabs>
    </Box>
  );
}
