// action - state management
import { LOGIN, LOGOUT } from './actions';

// initial state
export const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};



// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN: {
      const { user } = action.payload;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        section: null,
        user: null,
        selectedSection: null, // Reset selectedSection on logout
      };
    }
    case 'SET_SECTION': { // Action to set section data
      return {
        ...state,
        section: action.payload,
      };
    }
    case 'SET_SELECTED_SECTION': { // Action to set the selected section index
      return {
        ...state,
        selectedSection: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};
export default auth;
